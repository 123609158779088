import React, { useState } from "react";
import classes from "./user.module.css";
import { TableRow, TableCell, Radio, Button } from "@mui/material";
import moment from "moment";
import { getBtnStyles } from "../../Utilities/commonFunctions";
import { DeleteBinIcon, EditPenIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import { deleteCustomer } from "../../Redux/Actions/customerActions";
import ConfirmModal from "../../Components/confirmModel";

const UserTableRows = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { error, customersList } = useSelector((state) => state.customerData);

  const handleClickDelete = () => {
    dispatch(pageLoader(true));
    dispatch(deleteCustomer(item.id, setErrorAlert, setSuccessAlert));
    closeModal();
  };

  const closeModal = () => {
    setErrorAlert();
    setSuccessAlert();
    setDeleteModal();
  };

  return (
    <>
      <TableRow className={classes.TableDataRows}>
        <TableCell
          flex={1}
          style={{ minWidth: 72 }}
          className={classes.radioContainer}
        >
          <Radio inputProps={{ "aria-label": "B" }} />
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          {item.name}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          {item.phone}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          {item.email}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          {moment(item.registration_date).format("DD-M-YYYY")}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          {item.location || ""}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 175 }}>
          {moment(item.last_login).format("DD-M-YYYY,hh:mm")}
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          <Button
            className={classes.statusBtn}
            style={getBtnStyles(item.status)}
          >
            {item.status}
          </Button>
        </TableCell>
        <TableCell flex={1} style={{ minWidth: 125 }}>
          <div className={classes.ActionBtnContainer}>
            <Button
              variant="contained"
              className={classes.actionBtn}
              onClick={() => navigate(`/update-user/${item.id}`)}
            >
              <EditPenIcon />
              <span>Edit</span>
            </Button>
            <Button
              variant="contained"
              className={classes.actionBtn}
              onClick={() => setDeleteModal(true)}
            >
              <DeleteBinIcon />
              <span>Delete</span>
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {deleteModal && (
        <ConfirmModal
          modalClose={closeModal}
          heading={"Delete Confirmation"}
          subHeading={`Are you sure want to delete ${item.name}`}
          callback={handleClickDelete}
        />
      )}
    </>
  );
};

export default UserTableRows;
