import React, { useState } from "react";
import classes from "./auth.module.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Typography, Button, InputAdornment, IconButton } from "@mui/material";
import DefaultGuestLayout from "./defaultGuestLayout";
import { useNavigate } from "react-router-dom";
import { CloseEyeIcon, OpenEyeIcon } from "../../Assets/Icons/icons";
import SuccessModal from "../../Components/successModal";
import { useDispatch } from "react-redux";
import { ResetPassword, pageLoader } from "../../Redux/Actions/userActions";

const ChangePassword = () => {
  const ChangePasswordDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [focusedElement, setFocusedElement] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [successModal, setSuccessModal] = useState(false);
    const userData = JSON.parse(sessionStorage.getItem("change_password_data"));

    const handleSubmit = () => {
      if (passwordsMatch) {
        const userEntry = {
          password: password,
          id: userData.id,
        };
        dispatch(pageLoader(true));
        dispatch(ResetPassword(userEntry, navigate, setErrorAlert));
      }
    };

    const handleFocus = (name) => {
      setFocusedElement(name);
    };

    const handleBlur = () => {
      setFocusedElement(null);
    };

    const closeModal = () => {
      setSuccessModal(false);
    };

    const handleNewPasswordChange = (e) => {
      const newPasswordValue = e.target.value;
      console.log(newPasswordValue);
      setPassword(newPasswordValue);
      if (confirmPassword && confirmPassword.length > 0) {
        const passwordsMatch = confirmPassword === newPasswordValue;
        setPasswordsMatch(passwordsMatch);
      }
    };

    const handleConfirmPasswordChange = (e) => {
      const confirmPasswordValue = e.target.value;
      setConfirmPassword(confirmPasswordValue);
      const passwordsMatch = password === confirmPasswordValue;
      setPasswordsMatch(passwordsMatch);
    };

    return (
      <div className={classes.LoginDivMainContainer}>
        <Typography variant="h3" className={`mb-2 ${classes.LoginMainHeader}`}>
          Create a New Password
        </Typography>
        <Typography variant="h6" className={classes.LoginSubHeader}>
          Create your new password with minimum of 8 characters
        </Typography>
        <ValidatorForm
          useref="form"
          className={classes.ValidatorForm}
          onSubmit={handleSubmit}
        >
          <div className={`mt-4 ${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              New Password
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="passwordInput"
              placeholder="Enter  your new password"
              className={`${classes.formInputFeild} ${
                focusedElement === "password" && classes.focusedInput
              } ${password && password.length < 8 && classes.errorInput}`}
              value={password}
              size="normal"
              onChange={handleNewPasswordChange}
              onFocus={() => handleFocus("password")}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className={classes.eyeIcon}
                    >
                      {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              validators={["required"]} // Add this line
              errorMessages={["Password is required"]}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              helperText={
                password &&
                password.length < 8 &&
                "Password should be at least 8 characters"
              }
            />
          </div>
          <div className={`mt-4 ${classes.formInputDiv}`}>
            <Typography className={`mb-2 ${classes.formLable}`}>
              Confirm Password
            </Typography>
            <StyledInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              label=""
              variant="outlined"
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPasswordInput"
              placeholder="Confirm your new password"
              className={`${classes.formInputFeild} ${
                focusedElement === "confirmPassword" && classes.focusedInput
              }`}
              value={confirmPassword}
              size="normal"
              onChange={handleConfirmPasswordChange}
              onFocus={() => handleFocus("confirmPassword")}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                      className={classes.eyeIcon}
                    >
                      {showConfirmPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              error={!passwordsMatch}
              helperText={!passwordsMatch ? "Passwords do not match" : ""}
              validators={["required"]} // Add this line
              errorMessages={["Confirm Password is required"]}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            className={`mt-5 ${classes.loginBtn}`}
            fullWidth
          >
            Create
          </Button>
        </ValidatorForm>
        {successModal && (
          <SuccessModal
            modalClose={closeModal}
            heading={"Your password has been reset successfullly"}
            subHeading={"Redirecting to Login page..."}
            loader={true}
          />
        )}
      </div>
    );
  };
  return <DefaultGuestLayout children={<ChangePasswordDiv />} />;
};

export default ChangePassword;
