import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./popup.css";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React, { useState, useCallback, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const containerStyle = {
  width: "100%",
  height: "400px",
};

function GoogleMapPop(props) {
  const { open, handleClose,isLoaded,setMap,marker,centerPoint, setCenterPoint ,handleClick,handleAddMaps} = props;
  const [isMounted, setIsMounted] = useState(false);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);


  console.log("marker 25 =",marker);
  console.log("centerPoint 26 =",centerPoint);

  useEffect(() => {
    
    // let my_res = JSON.parse(sessionStorage.getItem("property_info"));

    // if (my_res?.property_cordinates?.coordinates) {
    //   setCenterPoint({lat: my_res?.property_cordinates?.coordinates[0],lng:my_res?.property_cordinates?.coordinates[1]})
    // } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          
          const new_center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenterPoint(new_center);
        });
      } else {
        alert("Geolocation is not available in your browser.");
      }
    // }
  }, []);

  
  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="google-map"
      >
        <Box>
          <Box className="d-flex-between popup-header">
            <Typography fontSize={"20px"}>Select your location</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
          <Box p={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              justifyContent={"end"}
              mb={2}
            >
              {/* <Button
                size="small"
                className={"retake-btn"}
                onClick={handleClose}
              >
                Reset
              </Button> */}
              <Button
                size="small"
                className={"capture-btn"}
                  onClick={handleAddMaps}
              >
                Add
              </Button>
            </Box>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={centerPoint}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={handleClick}
              >
                {marker  && (
                  <Marker position={{ lat: marker.lat, lng: marker.lng }} />
                )}
              </GoogleMap>
            ) : (
              <Typography>Not found</Typography>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default GoogleMapPop;
