import React, { useEffect } from "react";
import classes from "./layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import SideNavBar from "./sideNavBar";
import DefaultHeaders from "./defaultHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageLoader from "../Components/Loader/loader";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, toggleSideBar, showLogoutModal } = useSelector(
    (state) => state.userLogin
  );

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const name = queryParams.get("name");
  const age = queryParams.get("age");

  useEffect(() => {
    const existData = sessionStorage.getItem("QueryData");
    if (!existData) {
      const UserData = {
        name: name,
        age: age,
      };
      sessionStorage.setItem("QueryData", JSON.stringify(UserData));
    }
  }, [queryParams]);
  return (
    <div className={classes.DefaultLayoutMaindiv}>
      <div
        className={
          toggleSideBar
            ? classes.sideNavBarParent
            : `${classes.sideNavBarParent} ${classes.toggledSidebarParent}`
        }
      >
        <SideNavBar />
      </div>
      <div
        className={
          toggleSideBar
            ? classes.headerParent
            : `${classes.headerParent} ${classes.toggledHeaderParent}`
        }
      >
        <DefaultHeaders />
      </div>
      <div
        className={
          toggleSideBar
            ? classes.MainBodyChildDiv
            : `${classes.MainBodyChildDiv} ${classes.toggledMainBodyChildDiv}`
        }
      >
        <div>
          <Outlet />
        </div>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <PageLoader />
        </Backdrop>
      )}
      {/* {showLogoutModal && (
        <SuccessModal
          modalClose={modalClose}
          width={335}
          height={153}
          type={"logout"}
          callback={handleLogout}
        />
      )} */}
    </div>
  );
};

export default DefaultLayout;
