import {
  Typography,
  Box,
  MenuItem,
  Button,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Pagination,
  TextField,
  Popover,
} from "@mui/material";
import classes from "./propertys.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  ApproveIcon,
  ArrowsIcon,
  DeactivateIcon,
  DeleteIcon,
  DeleteIconWhite,
  DotGreenIcon,
  EditIcon,
  EditIconWhite,
  ExportIcon,
  GridIcon,
  ListtIcon,
  LocationIconLite,
  OwnerIconLite,
  PriceIconLite,
  ResidentialIconLite,
  SaleIconLite,
  SoldIcon,
  WidthIconLite,
} from "../../Assets/Icons/icons";
import MyTabelData from "./MyTabelData";
import { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  deleteProperty,
  downloadExportExcel,
  saveSortOrder,
} from "../../Redux/Actions/propertyAction";
import GridItem from "./gridItem";
import { range } from "../../Utilities/commonConstants";

function MyTableContainer(props) {
  const {
    arr,
    tableDatas,
    handleChangePagination,
    handleEvent,
    totalCount,
    selectedDate,
  } = props;
  const [viewType, setViewType] = useState("list");
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const { tableSort } = useSelector((state) => state.propertyData);

  const handleDownloadReport = () => {
    dispatch(pageLoader(true));
    dispatch(downloadExportExcel(setErrorAlert));
  };
  const sortByList = [
    { value: "createdAt-asc", text: "Oldest First" },
    { value: "createdAt-desc", text: "Newest First" },
    { value: "view_count-desc", text: "Most Viwed" },
    { value: "view_count-asc", text: "Least Viwed" },
  ];

  return (
    <Box>
      <Box py={1} px={2} className="d-flex-between">
        <Box className="d-flex" gap={"20px"}>
          <Typography className={classes.sub_header}>
            Showing 1-9 of 13 results
          </Typography>
          <Button className={classes.export_btn} onClick={handleDownloadReport}>
            {" "}
            <ExportIcon /> Export to Excel
          </Button>
        </Box>
        <Box className="d-flex" gap={"15px"}>
          <div className={classes.datePicker1}>
            <DateRangePicker
              initialSettings={{ ranges: range }}
              alwaysShowCalendars={true}
              onApply={handleEvent}
            >
              <div>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  size="small"
                  placeholder="Today"
                  value={selectedDate ? selectedDate : ""}
                  className={`${selectedDate ? classes.dataInput : ""}`}
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ right: "10px" }}
                >
                  <FaRegCalendarAlt />{" "}
                </span>
              </div>
            </DateRangePicker>
          </div>
          <Typography className={classes.sub_header}>Sort By</Typography>
          <TextField
            variant="outlined"
            autoComplete="off"
            select
            sx={{ width: "170px" }}
            id="emailInput"
            placeholder="Expected by"
            className={`my-field select-field`}
            name={"tableSort"}
            value={tableSort}
            size="normal"
            onChange={(e) => dispatch(saveSortOrder(e.target.value))}
          >
            <MenuItem value={"-1"} sx={{ display: "none" }}>
              <p style={{ color: "rgba(0,0,0,0.3)" }}>Default</p>
            </MenuItem>
            {sortByList?.map((item, index) => {
              return (
                <MenuItem
                  value={item.value}
                  // onClick={(e) => dispatch(saveSortOrder(item))}
                >
                  {item?.text}
                </MenuItem>
              );
            })}
          </TextField>
          {viewType === "list" ? (
            <Typography
              onClick={() => setViewType("grid")}
              sx={{ cursor: "pointer" }}
            >
              <GridIcon />
            </Typography>
          ) : (
            <Typography
              onClick={() => setViewType("list")}
              sx={{ cursor: "pointer" }}
            >
              <ListtIcon />
            </Typography>
          )}
        </Box>
      </Box>
      {viewType === "list" ? (
        <TableContainer component={Paper} className={classes.TableContainer}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>
                  <p style={{ visibility: "hidden" }}>sss</p>
                </TableCell>
                <TableCell>
                  Property ID{" "}
                  <span style={{ cursor: "pointer" }}>
                    <ArrowsIcon />
                  </span>
                </TableCell>
                <TableCell>
                  Property Title{" "}
                  <span style={{ cursor: "pointer" }}>
                    <ArrowsIcon />
                  </span>
                </TableCell>
                <TableCell>Kind of Type</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDatas?.map((row, index) => (
                <MyTabelData row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box px={2} mt={2}>
          <Grid container spacing={2}>
            {tableDatas?.map((datas) => {
              return <GridItem datas={datas} key={datas._id} />;
            })}
          </Grid>
        </Box>
      )}

      <Box
        display={"flex"}
        justifyContent={"end"}
        my={3}
        className="property_pagination"
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          shape="rounded"
          sx={{ textAlign: "end" }}
          onChange={handleChangePagination}
        />
      </Box>
    </Box>
  );
}

export default MyTableContainer;
