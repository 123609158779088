import {
  AggrecutureLandIcon,
  CommercialBuildingIcon,
  LandsPlotsIcon,
  ResidentialHomeIcon,
} from "../Assets/Icons/icons";
import moment from "moment";

export const PropertyPostingList3 = [
  {
    text: "Plots/lands",
    value: "661cc347267fb2f57d21fb9a",
    icon: <LandsPlotsIcon />,
  },
  {
    text: "Residential",
    value: "661cde3d267fb2f57d2203e2",
    icon: <ResidentialHomeIcon />,
  },
  {
    text: "Commercial",
    value: "661cc37f267fb2f57d21fb9d",
    icon: <CommercialBuildingIcon />,
  },
  {
    text: "Agricultural/\nFarmlands",
    value: "661cc398267fb2f57d21fba0",
    icon: <AggrecutureLandIcon />,
  },
];

export const UserTypeList = [
  { text: "Owner", value: "owner" },
  { text: "Agent", value: "agent" },
];

export const LookingToList = [
  { text: "Sell", value: "sell" },
  { text: "Rent/Lease", value: "rent_lease" },
];

export const PropTypeList = [
  { text: "Residential Apartments", value: "residential_apartments" },
  { text: "House/Villa", value: "house_villa" },
  { text: "Independent Houses", value: "independent_houses" },
  { text: "Other", value: "other" },
];

export const bhkTypeList = [
  { text: "1RK", value: "1rk" },
  { text: "1BHK", value: "1bhk" },
  { text: "2BHK", value: "2bhk" },
  { text: "3BHK", value: "3bhk" },
  { text: "4BHk+", value: "4bhk+" },
  { text: "5+BHK", value: "5+bhk" },
];

export const noOfBathroomsList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const furnishedList = [
  { text: "furnished", value: "furnished" },
  { text: "Unfurnished", value: "unfurnished" },
  { text: "semi-furnished", value: "semi_furnished" },
];

export const constructionTypeList = [
  { text: "Under construction", value: "under_construction" },
  { text: "ready to move", value: "ready_to_move" },
];

export const range = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  "Last Year": [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
};
