import React, { useEffect, useState } from "react";
import classes from "./user.module.css";
import { Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Alphabetic, Numeric } from "../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Components/AlertBox";
import SuccessModal from "../../Components/successModal";
import { useNavigate } from "react-router-dom";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  createCustomer,
  getOneCustomer,
  updateCustomer,
} from "../../Redux/Actions/customerActions";

const AddUsers = () => {
  const [errorAlert, setErrorAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [location, setLocation] = useState("");
  const [blocked, setBlocked] = useState(false);
  const [email, setEmail] = useState("");
  const [focusedElement, setFocusedElement] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [user_id, setUser_id] = useState(null);
  const { success, error, oneCustomer } = useSelector(
    (state) => state.customerData
  );
  const handleFocus = (name) => {
    setFocusedElement(name);
  };
  const handleBlur = () => {
    setFocusedElement(null);
  };

  useEffect(() => {
    if (window.location.pathname.includes("update")) {
      const url = window.location.pathname.split("/");
      setUser_id(url[url.length - 1]);
      dispatch(pageLoader(true));
      dispatch(getOneCustomer(url[url.length - 1], setErrorAlert));
    }
  }, []);

  const closeModal = () => {
    setErrorAlert(false);
    if (successAlert) {
      setSuccessAlert(false);
      navigate("/users");
    }
  };

  useEffect(() => {
    if (oneCustomer && window.location.pathname.includes("update-user")) {
      setName(oneCustomer?.name);
      setMobile(oneCustomer?.phone);
      setEmail(oneCustomer?.email);
      setLocation(oneCustomer?.location);
    }
  }, [oneCustomer]);

  const handleSubmit = () => {
    const userEntry = {
      name: name,
      phone: mobile,
      email: email,
      location: location,
      status: "Inactive",
    };

    if (blocked) {
      userEntry.status = "Blocked";
    }

    if (window.location.pathname.includes("update")) {
      dispatch(pageLoader(true));
      dispatch(
        updateCustomer(
          user_id,
          userEntry,

          setErrorAlert,
          setSuccessAlert
        )
      );
    } else {
      dispatch(pageLoader(true));
      dispatch(
        createCustomer(userEntry, navigate, setErrorAlert, setSuccessAlert)
      );
    }
  };
  return (
    <div>
      <Typography className={classes.userIndexMainHeader}>Add Users</Typography>
      <div>
        <ValidatorForm
          useref="validatorForm"
          className={classes.addUserForm}
          onSubmit={handleSubmit}
        >
          <Typography className={classes.formLable}>Name</Typography>
          <StyledInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            label=""
            variant="outlined"
            fullWidth
            id="nameInput"
            placeholder="Enter Full Name"
            className={`${classes.formInputFeild} ${
              focusedElement === "name" && classes.focusedInput
            }`}
            value={name}
            onKeyPress={(e) => Alphabetic(e)}
            size="normal"
            onChange={(e) => setName(e.target.value)}
            onFocus={() => handleFocus("name")}
            onBlur={handleBlur}
            validators={["required"]} // Add this line
            errorMessages={["Name is required"]}
          />
          <Typography className={classes.formLable}>Phone Number</Typography>
          <StyledInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            label=""
            variant="outlined"
            fullWidth
            id="phoneInput"
            placeholder="Enter Phone"
            className={`${classes.formInputFeild} ${
              focusedElement === "phone" && classes.focusedInput
            }`}
            value={mobile}
            onKeyPress={(e) => Numeric(e)}
            inputProps={{ maxLength: 10 }}
            size="normal"
            onChange={(e) => setMobile(e.target.value)}
            onFocus={() => handleFocus("phone")}
            onBlur={handleBlur}
            validators={["required"]} // Add this line
            errorMessages={["Email is required"]}
          />
          <Typography className={classes.formLable}>Email</Typography>
          <StyledInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            label=""
            variant="outlined"
            fullWidth
            id="emailInput"
            placeholder="Enter Email Address"
            className={`${classes.formInputFeild} ${
              focusedElement === "email" && classes.focusedInput
            }`}
            value={email}
            size="normal"
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => handleFocus("email")}
            onBlur={handleBlur}
            // validators={["required", "isEmail"]} // Add this line
            // errorMessages={["Email is required", "Email is not valid"]}
          />
          <Typography className={classes.formLable}>
            Location(optional)
          </Typography>
          <StyledInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            label=""
            variant="outlined"
            fullWidth
            id="locationInput"
            placeholder=""
            className={`${classes.formInputFeild} ${
              focusedElement === "locationInput" && classes.focusedInput
            }`}
            value={location}
            size="normal"
            onChange={(e) => setLocation(e.target.value)}
            onFocus={() => handleFocus("locationInput")}
            onBlur={handleBlur}
          />
          <div />
          <FormControlLabel
            control={
              <Checkbox
                checked={blocked}
                onChange={(e) => setBlocked(e.target.checked)}
                className={classes.Checkbox}
              />
            }
            label="Block User"
          />
          {/* <Checkbox
            checked={blocked}
            onChange={(e) => setBlocked(e.target.checked)}
            className={classes.Checkbox}
          /> */}
          <div />
          <Button
            type="submit"
            variant="contained"
            className={`mt-3 ${classes.commonBtn}`}
            fullWidth
          >
            Add
          </Button>
        </ValidatorForm>
      </div>
      {successAlert && success && (
        <SuccessModal
          modalClose={closeModal}
          heading={
            window.location.pathname.includes("update")
              ? "User Details Updated"
              : "New User Added"
          }
          subHeading={
            window.location.pathname.includes("update")
              ? "You have updated the user details successfully"
              : "You've successfully added a new user"
          }
        />
      )}
      {errorAlert && error && (
        <AlertBox type={"error"} stateName={closeModal} message={error} />
      )}
    </div>
  );
};

export default AddUsers;
