import React, { useState } from "react";
import classes from "./propertys.module.css";
import { Box, Grid, Typography, Button, Popover } from "@mui/material";
import {
  ApproveIcon,
  DeactivateIcon,
  DeleteIcon,
  DeleteIconWhite,
  DotGreenIcon,
  EditIcon,
  EditIconWhite,
  LocationIconLite,
  OwnerIconLite,
  PriceIconLite,
  ResidentialIconLite,
  SaleIconLite,
  SoldIcon,
  WidthIconLite,
} from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  deleteProperty,
  updatePropertyApi,
} from "../../Redux/Actions/propertyAction";
import ConfirmModal from "../../Components/confirmModel";

const GridItem = ({ datas, key }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [errorAlert, setErrorAlert] = useState(false);

  const CloseAlert = () => {
    setDeleteConfirmationModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    const id = window.location.pathname.split("/").pop();
    dispatch(pageLoader(true));
    dispatch(deleteProperty(datas._id, setErrorAlert));
    setAnchorEl(null);
  };

  const handleClickOption = (data) => {
    const userEntry = {
      property_status: data,
    };
    dispatch(pageLoader(true));
    dispatch(updatePropertyApi(datas._id, userEntry, setErrorAlert));
    setAnchorEl(null);
  };

  const getInitialName = (name) => {
    const arr = name.split(" ");
    let initial;
    if (arr.length > 1) {
      initial = arr[0][0] + arr[1][0];
      return initial?.toUpperCase();
    }
    initial = arr[0][0] + arr[0][1];
    return initial?.toUpperCase();
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Box className={classes.grid_wrapper}>
        <Box width={"28%"} height={"100%"}>
          <img
            src={`${process.env.REACT_APP_API_URL}/${datas.photos[0]?.image}`}
            alt="property_image"
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <Box
          width={"72%"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Box className="d-flex-between">
            <Typography className={classes.grid_view_title}>
              {datas?.created_user?.name} <br /> <span>{datas.city}</span>{" "}
            </Typography>
            {datas.property_status === "Active" ? (
              <span className={classes.active_prop}>Active</span>
            ) : datas.property_status === "Pending_Approval" ? (
              <span className={classes.pending_prop}>Pending Approval</span>
            ) : datas.property_status === "Sold" ? (
              <span className={classes.sold_prop}>Sold</span>
            ) : datas.property_status === "Deactivated" ? (
              <span className={classes.deactivate_prop}>Deactivated</span>
            ) : datas.property_status === "Deleted" ? (
              <span className={classes.deactivate_prop}>Deleted</span>
            ) : null}
          </Box>
          <Box className="d-flex" gap={"25px"}>
            <Typography className={classes.grid_view_details}>
              <ResidentialIconLite /> {datas?.kind_of_property}{" "}
            </Typography>
            <Typography className={classes.grid_view_details}>
              <LocationIconLite /> {datas?.city}{" "}
            </Typography>
            <Typography className={classes.grid_view_details}>
              <PriceIconLite /> {datas?.price?.expected_price?.toLocaleString()}
            </Typography>
          </Box>
          <Box className="d-flex" gap={"25px"}>
            <Typography className={classes.grid_view_details}>
              <WidthIconLite /> {datas.area_of_property} Sq.ft{" "}
            </Typography>
            <Typography className={classes.grid_view_details}>
              <OwnerIconLite /> By {datas.user_type}{" "}
            </Typography>
            <Typography className={classes.grid_view_details}>
              <SaleIconLite /> For {datas.offer_type}{" "}
            </Typography>
          </Box>
          <Box className="d-flex-between">
            <Box className="d-flex" gap={"5px"}>
              <Typography className={classes.first_letter}>
                {getInitialName(datas.created_user.name)}
              </Typography>
              <b>{datas.created_user.name}</b>
            </Box>
            <Box className="d-flex" gap={"10px"}>
              <Typography
                className={classes.edit_delete}
                onClick={() => navigate(`/property/edit-property/${datas._id}`)}
              >
                <EditIconWhite />
              </Typography>
              <Typography
                className={classes.edit_delete}
                onClick={() => setDeleteConfirmationModal(true)}
              >
                <DeleteIconWhite />
              </Typography>
              <Typography sx={{ cursor: "Pointer" }} onClick={handleClick}>
                <DotGreenIcon />
              </Typography>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {datas.property_status === "Sold" ? (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                    p={2}
                  >
                    <Button
                      className={classes.popper_btns}
                      onClick={() =>
                        navigate(`/property/edit-property/${datas._id}`)
                      }
                    >
                      <EditIcon /> Edit
                    </Button>
                    <Button
                      className={classes.popper_btns}
                      onClick={() => setDeleteConfirmationModal(true)}
                    >
                      <DeleteIcon /> Delete{" "}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                    p={2}
                  >
                    {datas.property_status === "Pending_Approval" ? (
                      <Button
                        className={classes.popper_btns}
                        onClick={() => handleClickOption("Active")}
                      >
                        <ApproveIcon /> Approve
                      </Button>
                    ) : null}
                    <Button
                      className={classes.popper_btns}
                      onClick={() => {
                        navigate(`/property/edit-property/${datas._id}`);
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon /> Edit
                    </Button>
                    {datas.property_status !== "Pending_Approval" ? (
                      <Button
                        className={classes.popper_btns}
                        onClick={() => handleClickOption("Sold")}
                      >
                        <SoldIcon /> Mark as Sold
                      </Button>
                    ) : null}
                    <Button
                      className={classes.popper_btns}
                      onClick={() => handleClickOption("Deactivated")}
                    >
                      <DeactivateIcon /> Deactivate
                    </Button>
                    <Button
                      className={classes.popper_btns}
                      onClick={() => setDeleteConfirmationModal(true)}
                    >
                      <DeleteIcon /> Delete{" "}
                    </Button>
                  </Box>
                )}
              </Popover>
            </Box>
          </Box>
        </Box>
      </Box>
      {deleteConfirmationModal && (
        <ConfirmModal
          modalClose={CloseAlert}
          heading={"Delete Confirmation"}
          subHeading={`Are you sure want to delete this property?`}
          callback={handleClickDelete}
        />
      )}
    </Grid>
  );
};

export default GridItem;
