import React, { useEffect, useState, memo } from "react";
import classes from "./property.module.css";
import {
  AggrecutureLandIcon,
  CommercialBuildingIcon,
  LandsPlotsIcon,
  ResidentialHomeIcon,
} from "../../Assets/Icons/icons";
import {
  Typography,
  Card,
  Stack,
  Chip,
  Divider,
  Button,
  Checkbox,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import { PropertyPostingList3 } from "../../Utilities/commonConstants";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { Col, Row } from "react-bootstrap";
import AlertBox from "../../Components/AlertBox";
import DragAndDrops from "./dragAndDrops";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../Redux/Actions/userActions";
import {
  AddPropertyApi,
  deleteProperty,
  getFeildsList,
  getOnePropertyDetails,
  updatePropertyApi,
  uploadsFiles,
} from "../../Redux/Actions/propertyAction";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  ActiveWhiteIcon,
  CurrancyWhiteIcon,
  DeactiveWhiteIcon,
  DeleteIconWhite,
} from "../../Assets/Icons/icons";
import ConfirmModal from "../../Components/confirmModel";
import SuccessModal from "../../Components/successModal";
import { Numeric, numberWithCommas } from "../../Utilities/commonFunctions";
import VideoModal from "./videoModal";
import GoogleMapPop from "../popups/GoogleMapPop";
import { useJsApiLoader } from "@react-google-maps/api";

let _areas = ["sq.ft", "sq.yard", "sq.me", "cent", "gunta", "acre", "hectare"];

const VideoItem = memo(({ src, alt, className, callback }) => (
  <video src={src} alt={alt} className={className} onClick={callback} />
));

const AddProperty = () => {
  const [yourProperty, setYourProperty] = useState();
  const [yourPropertyName, setYourPropertyName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [focusedElement, setFocusedElement] = useState(null);
  const [userType, setUserType] = useState("");
  const [lookingTo, setLookingTo] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertyTypeName, setPropertyTypeName] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [apartmentSociety, setApartmentSociety] = useState("");
  const [locality, setLocality] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [propertyArea, setPropertyArea] = useState("");
  const [anyConstruction, setAnyConstruction] = useState("");
  const [propertyLength, setPropertyLength] = useState("");
  const [propertyWidth, setPropertyWidth] = useState("");
  const [bhkType, setBhkType] = useState("");
  const [floorDetails, setFloorDetails] = useState("");
  const [propertyOnFloor, setPropertyOnFloor] = useState("");
  const [noOfWashrooms, setNoOfWashrooms] = useState("");
  const [noOfBalconies, setNoOfBalconies] = useState("");
  const [availability, setAvailability] = useState("");
  const [availabilityName, setAvailabilityName] = useState("");
  const [underConstruction, setUnderConstruction] = useState("");
  const [ageOfProperty, setAgeOfProperty] = useState("");
  const [rooms, setRooms] = useState("");
  const [areYouBuilder, setAreYouBuilder] = useState("");
  const [noOfBedRooms, setNoOfBedRooms] = useState("");
  const [noOfBathrooms, setNoOfBathrooms] = useState("");
  const [noOfOpenSides, setNoOfOpenSides] = useState("");
  const [furnishingStatus, setFurnishingStatus] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);
  const [price, setPrice] = useState("");
  const [approvedAuthority, setApprovedAuthority] = useState("");
  const [preLeased, setPreLeased] = useState("");
  const [widthOfRoadFacing, setWidthOfRoadFacing] = useState("");
  const [areaForBhk, setAreaForBhk] = useState("");
  const [noOfUnits, setNoOfUnits] = useState("");
  const [pricingDDetails, setPricingDDetails] = useState([]);
  const [aminities, setAminities] = useState([]);
  const [otherFeatures, setOtherFeatures] = useState([]);
  const [powerBackup, setPowerBackup] = useState("");
  const [propertyFacing, setPropertyFacing] = useState("");
  const [locationAdvantages, setLocationAdvantages] = useState([]);
  const [otherAminities, setOtherAminities] = useState("");
  const [pricedetails, setPricedetails] = useState("");
  const [OtherAminitiesDetails, setOtherAminitiesDetails] = useState("");
  const [ownership, setOwnership] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [playVideo, setPlayVideo] = useState(null);
  const { feildsList, onePropertyDetails, error, success } = useSelector(
    (state) => state.propertyData
  );
  const [units, setUnits] = useState({
    area_selected_unit: "sq.ft",
    built_up_area_unit: "sq.ft",
    super_built_up_area_unit: "sq.ft",
    coordinates: [],
  });
  const [builtUpArea, setBuiltUpArea] = useState("");
  const [superBuiltUpArea, setSuperBuiltUpArea] = useState("");
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [centerPoint, setCenterPoint] = useState({
    lat: null,
    lng: null,
  });
  const [mapPopup, setMapPopup] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const loadVideo = (data) => {
    setPlayVideo(data);
    setVideoModal(true);
  };

  const CloseAlert = () => {
    // setSuccessAlert(false);
    setErrorAlert(false);
    setDeleteConfirmation(false);
    setVideoModal(false);
    if (successAlert) {
      setSuccessAlert(false);
      navigate("/property");
    }
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getFeildsList(setErrorAlert));
  }, []);

  const formatToIndianCurrency = (value) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(value);
  };
  console.log("onePropertyDetails =", onePropertyDetails);
  useEffect(() => {
    if (onePropertyDetails && window.location.pathname.includes("edit")) {
      const propertyName = feildsList?.kind_of_property?.find(
        (item) =>
          item._id === onePropertyDetails?.originalData?.kind_of_property
      );
      setYourProperty(onePropertyDetails?.originalData?.kind_of_property);
      setYourPropertyName(propertyName?.text);
      setUserType(onePropertyDetails?.originalData?.user_type);
      setLookingTo(onePropertyDetails?.originalData?.offer_type);
      setUserName(onePropertyDetails?.originalData?.created_user?.name);
      setPhoneNumber(onePropertyDetails?.originalData?.created_user?.phone);
      setPropertyType(onePropertyDetails?.originalData?.property_type);
      setEmail(onePropertyDetails?.originalData?.created_user?.email);
      setPropertyArea(onePropertyDetails?.originalData?.area_of_property);
      setBhkType(onePropertyDetails?.originalData?.room_details?.bhk_type);
      setFloorDetails(
        onePropertyDetails?.originalData?.floor_details?.total_floors
      );
      setPropertyOnFloor(
        onePropertyDetails?.originalData?.floor_details?.property_on_floor
      );
      setAvailability(onePropertyDetails?.originalData?.availability);
      setUnderConstruction(
        onePropertyDetails?.originalData?.expected_time_of_possession
      );
      setCity(
        onePropertyDetails?.originalData?.city ||
          onePropertyDetails?.originalData?.location
      );
      setApartmentSociety(onePropertyDetails?.originalData?.apartment_society);
      setLocality(onePropertyDetails?.originalData?.locality);
      setHouseNumber(onePropertyDetails?.originalData?.house_number);
      setPricedetails(onePropertyDetails?.originalData?.property_details);
      setOtherFeatures(onePropertyDetails?.originalData?.other_features);
      setPowerBackup(onePropertyDetails?.originalData?.power_backup);
      setNoOfBedRooms(
        onePropertyDetails?.originalData?.room_details?.no_of_bedrooms
      );
      setNoOfBathrooms(
        onePropertyDetails?.originalData?.room_details?.no_of_bathrooms
      );
      setFurnishingStatus(
        onePropertyDetails?.originalData?.room_details?.furnishing_status
      );
      setOwnership(onePropertyDetails?.originalData?.ownership);
      setRooms(onePropertyDetails?.originalData?.room_details?.other_rooms);
      setPrice(
        numberWithCommas(
          onePropertyDetails?.originalData?.price?.expected_price
        )
      );
      setPricingDDetails(
        onePropertyDetails?.originalData?.price?.pricing_details
      );
      setImages(onePropertyDetails?.originalData?.photos);
      setVideos(onePropertyDetails?.originalData?.video);
      setFiles([
        ...onePropertyDetails?.originalData?.photos,
        ...onePropertyDetails?.originalData?.video,
      ]);
      setAminities(onePropertyDetails?.originalData?.amenities);
      setPropertyFacing(onePropertyDetails?.originalData?.property_facing);
      setLocationAdvantages(
        onePropertyDetails?.originalData?.location_advantages
      );
      setOtherAminities(onePropertyDetails?.originalData?.water_source);
      setOtherAminitiesDetails(
        onePropertyDetails?.originalData?.suggest_property_features
      );
      setAgeOfProperty(onePropertyDetails?.originalData?.age_of_property);
      setPropertyLength(
        onePropertyDetails?.originalData?.property_dimensions?.length
      );
      setPropertyWidth(
        onePropertyDetails?.originalData?.property_dimensions?.breadth
      );
      setWidthOfRoadFacing(
        onePropertyDetails?.originalData?.width_of_facing_road
      );
      setNoOfOpenSides(onePropertyDetails?.originalData?.no_of_open_sides);
      setAnyConstruction(
        onePropertyDetails?.originalData?.any_construction_done
      );
      setAreYouBuilder(onePropertyDetails?.originalData?.are_you_builder);
      setNoOfWashrooms(
        onePropertyDetails?.originalData?.room_details?.no_of_washrooms
      );
      setNoOfBalconies(
        onePropertyDetails?.originalData?.room_details?.no_of_balcony
      );
      setPreLeased(onePropertyDetails?.originalData?.pre_released_pre_rented);
      setApprovedAuthority(
        onePropertyDetails?.originalData?.approved_authority
      );

      setBuiltUpArea(onePropertyDetails?.originalData?.built_up_area);
      setSuperBuiltUpArea(
        onePropertyDetails?.originalData?.super_built_up_area
      );
      setUnits({
        area_selected_unit:
          onePropertyDetails?.originalData?.area_selected_unit,
        built_up_area_unit:
          onePropertyDetails?.originalData?.built_up_area_unit,
        super_built_up_area_unit:
          onePropertyDetails?.originalData?.super_built_up_area_unit,
        coordinates:
          onePropertyDetails?.originalData?.property_cordinates?.coordinates,
      });
      setMarker({
        lat: onePropertyDetails?.originalData?.property_cordinates
          ?.coordinates[0],
        lng: onePropertyDetails?.originalData?.property_cordinates
          ?.coordinates[1],
      });
    }
  }, [onePropertyDetails]);

  const getImagesArray = async () => {
    const imagesArray = await images
      ?.filter((image) => !image.mime_type)
      .map(async (image) => {
        dispatch(pageLoader(true));
        const responce = await dispatch(uploadsFiles(image, setErrorAlert));
        return responce;
      });

    const results = await Promise.all(imagesArray);
    const imagesList = await results.map((result) => {
      const imageData = {
        original_name: result.originalname,
        mime_type: result.mimetype,
        image: `${result.destination}${result.filename}`,
        type: "",
      };
      return imageData;
    });
    const filtereredList = images.filter((image) => image.mime_type);

    return [...imagesList, ...filtereredList];
  };
  const getVideosArray = async () => {
    const imagesArray = await videos
      ?.filter((image) => !image.mime_type)
      .map(async (image) => {
        dispatch(pageLoader(true));
        const responce = await dispatch(uploadsFiles(image, setErrorAlert));
        return responce;
      });

    const results = await Promise.all(imagesArray);
    const imagesList = await results.map((result) => {
      const imageData = {
        original_name: result.originalname,
        mime_type: result.mimetype,
        video_name: `${result.destination}${result.filename}`,
        type: "",
      };
      return imageData;
    });

    const filtereredList = videos.filter((image) => image.mime_type);
    return [...imagesList, ...filtereredList];
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("edit") ||
      window.location.pathname.includes("view")
    ) {
      const id = window.location.pathname.split("/").pop();
      dispatch(pageLoader(true));
      dispatch(getOnePropertyDetails(id, setErrorAlert));
    }
  }, []);

  const handleSubmit = async () => {
    const outputtedImagesArray = await getImagesArray();

    const outputtedVideosArray = await getVideosArray();

    let price_convert = price?.replaceAll(",", "");

    const userEntry = {
      user_type: userType,
      offer_type: lookingTo,
      kind_of_property: yourProperty,
      property_type: propertyType,
      area_of_property: propertyArea,
      room_details: {
        room_type: bhkType,
        no_of_bedrooms: noOfBedRooms,
        other_rooms: rooms,
        no_of_bathrooms: noOfBathrooms,
        furnishing_status: furnishingStatus,
        no_of_washrooms: noOfWashrooms,
        no_of_balcony: noOfBalconies,
      },
      property_dimensions: {
        length: propertyLength,
        breadth: propertyWidth,
      },
      approved_authority: approvedAuthority,
      pre_released_pre_rented: preLeased,
      width_of_facing_road: widthOfRoadFacing,
      no_of_open_sides: noOfOpenSides,
      any_construction_done: anyConstruction,
      floor_details: {
        total_floors: floorDetails,
        property_on_floor: propertyOnFloor,
      },
      availability: availability,
      expected_time_of_possession: underConstruction,
      age_of_property: ageOfProperty,
      city: city,
      location: locality,
      apartment_society: apartmentSociety,
      locality: locality,
      house_number: houseNumber,
      ownership: ownership,
      photos: outputtedImagesArray,
      video: outputtedVideosArray,
      price: {
        expected_price: parseInt(price_convert),
        pricing_details: pricingDDetails,
      },
      property_details: pricedetails,
      amenities: aminities,
      are_you_builder: areYouBuilder,
      other_features: otherFeatures,
      water_source: otherAminities,
      power_backup: powerBackup,
      property_facing: propertyFacing,
      location_advantages: locationAdvantages,
      suggest_property_features: OtherAminitiesDetails,
      super_built_up_area_unit: units.super_built_up_area_unit,
      built_up_area_unit: units.built_up_area_unit,
      area_selected_unit: units.area_selected_unit,
      property_cordinates: { type: "Point", coordinates: units.coordinates },
      built_up_area: builtUpArea,
      super_built_up_area: superBuiltUpArea,
    };

    if (outputtedImagesArray.length > 0 || outputtedVideosArray.length > 0) {
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        const id = window.location.pathname.split("/").pop();
        dispatch(
          updatePropertyApi(id, userEntry, setErrorAlert, setSuccessAlert)
        );
      } else {
        userEntry.created_user = {
          name: userName,
          phone: phoneNumber,
          email: email,
        };
        userEntry.property_status = "Active";
        dispatch(AddPropertyApi(userEntry, setErrorAlert, setSuccessAlert));
      }
    }
  };

  useEffect(() => {
    if (propertyType) {
      const propertyName = feildsList?.property_type?.find((item) => {
        return item?._id === propertyType;
      });
      setPropertyTypeName(propertyName?.text);
    }
    if (availability) {
      const availabilityName = feildsList?.availability?.find((item) => {
        return item?._id === availability;
      });
      setAvailabilityName(availabilityName?.text);
    }
  }, [propertyType, availability, yourPropertyName]);

  const removeFile = (index) => {
    const selectedItem = files[index];

    const isImage = images.find((i) => i === selectedItem);
    if (isImage) {
      setImages(images.filter((i) => i !== selectedItem));
      setFiles(files.filter((i) => i !== selectedItem));
    } else {
      setVideos(videos.filter((i) => i !== selectedItem));
      setFiles(files.filter((i) => i !== selectedItem));
    }
  };

  const handleFocus = (name) => {
    setFocusedElement(name);
  };

  const handleBlur = () => {
    setFocusedElement(null);
  };

  const onDropImages = (files) => {
    setImages((prev) => [...prev, ...files]);
    setFiles((prev) => [...prev, ...files]);
  };
  const onDropVideos = (files) => {
    setVideos((prev) => [...prev, ...files]);
    const url = URL.createObjectURL(files[0]);
    const obj = {
      url: url,
      name: files[0].name,
    };

    setFiles((prev) => [...prev, obj]);
  };

  const handleSetPricingDetails = (item) => {
    const isExist = pricingDDetails.find((i) => i === item);
    if (isExist) {
      setPricingDDetails(pricingDDetails.filter((i) => i !== item));
    } else {
      setPricingDDetails([...pricingDDetails, item]);
    }
  };

  const handleClickDelete = () => {
    const id = window.location.pathname.split("/").pop();
    dispatch(pageLoader(true));
    dispatch(deleteProperty(id, setErrorAlert));
  };

  const getProprtyTypeIcon = (text) => {
    switch (text) {
      case "Residential":
        return <ResidentialHomeIcon />;
      case "Commercial":
        return <CommercialBuildingIcon />;
      case "Plots/lands":
        return <LandsPlotsIcon />;
      case "Agricultural/Farmlands":
        return <AggrecutureLandIcon />;
      // case "New Projects":
      //   return <ResidentialHomeIcon />;
      default:
        return <CommercialBuildingIcon />;
    }
  };

  function handleClose() {
    setMapPopup(false);
  }

  const handleAddMaps = () => {
    setMapPopup(false);
  };

  const getGeocode = async (lat, lng) => {
    try {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          let lists = data?.results[0]?.address_components;
          console.log("lists =", lists);
          let map_city = "";
          let map_location = "";
          lists?.forEach((val) => {
            if (val.types?.includes("locality")) {
              map_location = val.long_name;
            }
            if (val.types?.includes("administrative_area_level_3")) {
              map_city = val.long_name;
            }
          });
          setCity(map_city);
          setLocality(map_location);
        })
        .catch((error) => {
          console.log(`Error getting location: ${error.message}`);
        });
    } catch (error) {
      console.error("Error fetching address:", error);
      console.log("Error fetching address");
    }
  };

  const handleClick = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setCenterPoint({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setUnits({
      ...units,
      coordinates: [event.latLng.lat(), event.latLng.lng()],
    });
    getGeocode(event.latLng.lat(), event.latLng.lng());
  };

  function handleChange(name, value) {
    setUnits({ ...units, [name]: value });
  }

  return (
    <div>
      {window.location.pathname.includes("edit") && (
        <div className={`mb-4 ${classes.HeaderSection}`}>
          <Typography className={classes.AddPropertyHeader}>
            Property Detail
          </Typography>
          {/* <div className={classes.headerBtnsContainer}>
            <Button variant="contained" className={classes.headerBtn}>
              <ActiveWhiteIcon />
              <span>Activate</span>
            </Button>
            <Button variant="contained" className={classes.headerBtn}>
              <DeactiveWhiteIcon />
              <span>Deactivate</span>
            </Button>
            <Button
              variant="contained"
              className={classes.headerBtn}
              onClick={() => setDeleteConfirmation(true)}
            >
              <DeleteIconWhite />
              <span>Delete</span>
            </Button>
            <Button variant="contained" className={classes.headerBtn}>
              <CurrancyWhiteIcon />
              <span>Mark as sold</span>
            </Button>
          </div> */}
        </div>
      )}
      <Card className={classes.AddPropertyMainCard}>
        <ValidatorForm
          onSubmit={handleSubmit}
          className={classes.AddPropertyForm}
          useref="form"
        >
          {window.location.pathname.includes("add") && (
            <Typography className={classes.AddPropertyHeader}>
              Add Property
            </Typography>
          )}
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Your Property Type
          </Typography>
          <Stack direction="row" spacing={1} className="mt-3">
            {feildsList?.kind_of_property?.map((item, index) => (
              <Chip
                icon={getProprtyTypeIcon(item?.text)}
                label={item?.text}
                variant={"filled"}
                key={index}
                onClick={() => {
                  setYourProperty(item?._id);
                  setYourPropertyName(item?.text);
                }}
                className={`${classes.propertyTypeChip} ${
                  yourProperty === item?._id && classes.PropertyActiveChip
                }`}
              />
            ))}
          </Stack>
          <Divider className="mt-4 mb-4" />
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Basic Details{" "}
          </Typography>
          <div className={`mt-3 ${classes.basicDetailsInputContainer}`}>
            <div className={classes.formInputContainer}>
              <Typography className={classes.AddPropertyInputLable}>
                User
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "userType" && classes.focusedInput
                }`}
                select
                value={userType}
                size="normal"
                onChange={(e) => setUserType(e.target.value)}
                onFocus={() => handleFocus("userType")}
                onBlur={handleBlur}
                validators={["required"]} // Add this line
                errorMessages={["Please Select User"]} // Add this line
              >
                {feildsList?.user_type?.map((item) => (
                  <MenuItem value={item?._id} key={item?._id}>
                    {item?.text}
                  </MenuItem>
                ))}
              </StyledInput>
            </div>
            <div className={classes.formInputContainer}>
              <Typography className={classes.AddPropertyInputLable}>
                Looking to
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "lookingTo" && classes.focusedInput
                }`}
                select
                value={lookingTo}
                size="normal"
                onChange={(e) => setLookingTo(e.target.value)}
                onFocus={() => handleFocus("lookingTo")}
                onBlur={handleBlur}
                validators={["required"]}
                errorMessages={["Please Select looking to"]}
              >
                {feildsList?.offer_type?.map((item) => (
                  <MenuItem value={item?._id} key={item?._id}>
                    {item?.text}
                  </MenuItem>
                ))}
              </StyledInput>
            </div>
            {yourPropertyName === "Agricultural/Farmlands" && (
              <>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Are you builder
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "areYouBuilder" && classes.focusedInput
                  }`}
                  value={areYouBuilder}
                  size="normal"
                  onChange={(e) => setAreYouBuilder(e.target.value)}
                  onFocus={() => handleFocus("areYouBuilder")}
                  onBlur={handleBlur}
                  select
                  validators={["required"]} // Add this line
                  errorMessages={["This field is required"]} // Add this line
                >
                  {["yes", "no"]?.map((item) => (
                    <MenuItem
                      value={item}
                      key={item}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </StyledInput>
              </>
            )}
            {yourPropertyName !== "Agricultural/Farmlands" &&
              yourPropertyName !== "New Projects" && (
                <div className={classes.formInputContainer}>
                  <Typography className={classes.AddPropertyInputLable}>
                    Property Type
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "propertyType" && classes.focusedInput
                    }`}
                    select
                    value={propertyType}
                    size="normal"
                    onChange={(e) => setPropertyType(e.target.value)}
                    onFocus={() => handleFocus("propertyType")}
                    onBlur={handleBlur}
                    // validators={["required"]} // Add this line
                    // errorMessages={["Please select property type"]} // Add this line
                  >
                    {feildsList?.property_type
                      ?.filter((item) => item.topic_type === yourProperty)
                      ?.map((item) => (
                        <MenuItem value={item?._id} key={item?._id}>
                          {item?.text}
                        </MenuItem>
                      ))}
                  </StyledInput>
                </div>
              )}
          </div>
          <div className={`mt-3 ${classes.basicDetailsInputContainer}`}>
            <div className={classes.formInputContainer}>
              <Typography className={classes.AddPropertyInputLable}>
                Name
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "userName" && classes.focusedInput
                }`}
                value={userName}
                disabled={
                  window.location.pathname.includes("edit") ? true : false
                }
                size="normal"
                onChange={(e) => setUserName(e.target.value)}
                onFocus={() => handleFocus("userName")}
                onBlur={handleBlur}
                validators={["required"]} // Add this line
                errorMessages={["User Name is required"]} // Add this line
              />
            </div>
            <div className={classes.formInputContainer}>
              <Typography className={classes.AddPropertyInputLable}>
                Ph.No
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "phoneNumber" && classes.focusedInput
                }`}
                value={phoneNumber}
                disabled={
                  window.location.pathname.includes("edit") ? true : false
                }
                size="normal"
                onChange={(e) => setPhoneNumber(e.target.value)}
                onFocus={() => handleFocus("phoneNumber")}
                onBlur={handleBlur}
                inputProps={{ maxLength: 10 }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required"]}
                errorMessages={["Phone number is required"]}
              />
            </div>
            <div className={classes.formInputContainer}>
              <Typography className={classes.AddPropertyInputLable}>
                Email Id
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "email" && classes.focusedInput
                }`}
                disabled={
                  window.location.pathname.includes("edit") ? true : false
                }
                value={email}
                size="normal"
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => handleFocus("email")}
                onBlur={handleBlur}
                validators={["required", "isEmail"]} // Add this line
                errorMessages={["Email Id is required", "Email Id is invalid"]} // Add this line
              />
            </div>
          </div>
          <Divider className="mt-4 mb-4" />
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Property Details
          </Typography>
          <div className={`${classes.formInputContainer} mt-3`}>
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Select Location
            </Typography>
            {marker ? (
              <Button fullWidth className={classes.location_select_view}>
                {marker?.lat},{marker?.lng}
                <MdEdit onClick={() => setMapPopup(true)} />
              </Button>
            ) : (
              <Button
                fullWidth
                className={classes.location_select}
                onClick={() => setMapPopup(true)}
              >
                Click to select
              </Button>
            )}
          </div>
          <div className={`mt-3 `}>
            <div
              className={`${classes.formInputContainer} ${classes.LocationContainer}`}
            >
              <Typography
                className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
              >
                Location
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "city" && classes.focusedInput
                }`}
                placeholder="Enter City*"
                value={city}
                size="normal"
                onChange={(e) => setCity(e.target.value)}
                onFocus={() => handleFocus("city")}
                onBlur={handleBlur}
                validators={["required"]} // Add this line
                errorMessages={["City is required"]} // Add this line
              />
              {yourPropertyName === "New Projects" && (
                <>
                  {" "}
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    No of units ?*
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "noOfUnits" && classes.focusedInput
                    }`}
                    placeholder="Total No.of units"
                    value={noOfUnits}
                    size="normal"
                    onChange={(e) => setNoOfUnits(e.target.value)}
                    onFocus={() => handleFocus("noOfUnits")}
                    onBlur={handleBlur}
                    validators={["required"]} // Add this line
                    errorMessages={["This feild is required"]} // Add this line
                  />
                </>
              )}
              {yourPropertyName === "Residential" && (
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "apartment/socity" &&
                    classes.focusedInput
                  }`}
                  placeholder="Enter Apartment/society*"
                  value={apartmentSociety}
                  size="normal"
                  onChange={(e) => setApartmentSociety(e.target.value)}
                  onFocus={() => handleFocus("apartment/socity")}
                  onBlur={handleBlur}
                  validators={["required"]} // Add this line
                  errorMessages={["Apartment is required"]} // Add this line
                />
              )}
              {yourPropertyName === "Residential" && (
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "locality" && classes.focusedInput
                  }`}
                  placeholder="Enter Locality*"
                  value={locality}
                  size="normal"
                  onChange={(e) => setLocality(e.target.value)}
                  onFocus={() => handleFocus("locality")}
                  onBlur={handleBlur}
                  validators={["required"]} // Add this line
                  errorMessages={["Locality is required"]} // Add this line
                />
              )}
              {yourPropertyName === "Residential" && (
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "houseNumber" && classes.focusedInput
                  }`}
                  placeholder="Enter House no"
                  value={houseNumber}
                  size="normal"
                  onChange={(e) => setHouseNumber(e.target.value)}
                  onFocus={() => handleFocus("houseNumber")}
                  onBlur={handleBlur}
                  validators={["required"]} // Add this line
                  errorMessages={["House no is required"]} // Add this line
                />
              )}
              {(yourPropertyName === "Plots/lands" ||
                yourPropertyName === "Agricultural/Farmlands") && (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Property dimensions
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "Length in ft" && classes.focusedInput
                    }`}
                    placeholder="Length in ft"
                    value={propertyLength}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    size="normal"
                    onChange={(e) => setPropertyLength(e.target.value)}
                    onFocus={() => handleFocus("Length in ft")}
                    onBlur={handleBlur}
                    validators={["required"]} // Add this line
                    errorMessages={["Property Area is required"]} // Add this line
                  />
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "Width in ft" && classes.focusedInput
                    }`}
                    placeholder="Breadth in ft"
                    value={propertyWidth}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    size="normal"
                    onChange={(e) => setPropertyWidth(e.target.value)}
                    onFocus={() => handleFocus("Width in ft")}
                    onBlur={handleBlur}
                    validators={["required"]} // Add this line
                    errorMessages={["Property Area is required"]} // Add this line
                  />
                </>
              )}
            </div>
            <div
              className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
            >
              <Typography
                className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
              >
                Property Area
              </Typography>
              <TextValidator
                variant="outlined"
                className={`${classes.formInputFeild} dropdown-select ${
                  focusedElement === "propertyArea" && classes.focusedInput
                }`}
                placeholder="Enter Area"
                value={propertyArea}
                size="normal"
                onChange={(e) => setPropertyArea(e.target.value)}
                onFocus={() => handleFocus("propertyArea")}
                onBlur={handleBlur}
                validators={["required"]} // Add this line
                errorMessages={["Property Area is required"]} // Add this line
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ margin: "0px" }}>
                      <TextValidator
                        variant="outlined"
                        autoComplete="off"
                        select
                        id="emailInput"
                        placeholder="Expected by"
                        className={`inner-select`}
                        name="expected_time_of_possession"
                        value={units.area_selected_unit}
                        size="normal"
                        validators={["required"]} // Add this line
                        errorMessages={[
                          "Expected time of possession is required",
                        ]}
                        style={{ width: "80px" }}
                      >
                        {_areas.map((item, index) => {
                          return (
                            <MenuItem
                              value={item}
                              key={index}
                              onClick={(e) =>
                                handleChange("area_selected_unit", item)
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </TextValidator>
                    </InputAdornment>
                  ),
                }}
              />
              {(yourPropertyName === "Residential" ||
                yourPropertyName === "New Projects") && (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    BHK Type
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "bhkType" && classes.focusedInput
                    }`}
                    value={bhkType}
                    size="normal"
                    onChange={(e) => setBhkType(e.target.value)}
                    onFocus={() => handleFocus("bhkType")}
                    onBlur={handleBlur}
                    select
                    validators={["required"]} // Add this line
                    errorMessages={["BHK Type is required"]} // Add this line
                  >
                    {feildsList?.room_type?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.text}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              )}
              {yourPropertyName !== "Plots/lands" &&
                yourPropertyName !== "Agricultural/Farmlands" && (
                  <>
                    {" "}
                    <Typography
                      className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                    >
                      Floor Details
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      className={`${classes.formInputFeild} ${
                        focusedElement === "floorDetails" &&
                        classes.focusedInput
                      }`}
                      placeholder="How many floors*"
                      value={floorDetails}
                      size="normal"
                      onChange={(e) => setFloorDetails(e.target.value)}
                      onFocus={() => handleFocus("floorDetails")}
                      onBlur={handleBlur}
                      validators={["required"]} // Add this line
                      errorMessages={["Floor Details is required"]} // Add this line
                    ></StyledInput>
                  </>
                )}
              {(yourPropertyName === "Plots/lands" ||
                yourPropertyName === "Agricultural/Farmlands") && (
                <>
                  {" "}
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Width of facing road
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "widthOfRoadFacing" &&
                      classes.focusedInput
                    }`}
                    placeholder="Enter width of the road"
                    value={widthOfRoadFacing}
                    size="normal"
                    onChange={(e) => setWidthOfRoadFacing(e.target.value)}
                    onFocus={() => handleFocus("widthOfRoadFacing")}
                    onBlur={handleBlur}
                    validators={["required"]} // Add this line
                    errorMessages={["This field is required"]} // Add this line
                  ></StyledInput>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Any construction
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "anyConstruction" &&
                      classes.focusedInput
                    }`}
                    value={anyConstruction}
                    size="normal"
                    onChange={(e) => setAnyConstruction(e.target.value)}
                    onFocus={() => handleFocus("anyConstruction")}
                    onBlur={handleBlur}
                    select
                    validators={["required"]} // Add this line
                    errorMessages={["This field is required"]} // Add this line
                  >
                    {["yes", "no"]?.map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              )}
            </div>
            {yourPropertyName === "Residential" && (
              <div
                className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
              >
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Additional Area
                </Typography>
                <TextValidator
                  variant="outlined"
                  className={`${classes.formInputFeild} dropdown-select ${
                    focusedElement === "builtUpArea" && classes.focusedInput
                  }`}
                  placeholder="Builtup Area"
                  value={builtUpArea}
                  size="normal"
                  onChange={(e) => setBuiltUpArea(e.target.value)}
                  onFocus={() => handleFocus("builtUpArea")}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ margin: "0px" }}>
                        <TextValidator
                          variant="outlined"
                          autoComplete="off"
                          select
                          id="emailInput"
                          placeholder="Expected by"
                          className={`inner-select`}
                          name="expected_time_of_possession"
                          value={units.built_up_area_unit}
                          size="normal"
                          validators={["required"]} // Add this line
                          errorMessages={[
                            "Expected time of possession is required",
                          ]}
                          style={{ width: "80px" }}
                        >
                          {_areas.map((item, index) => {
                            return (
                              <MenuItem
                                value={item}
                                key={index}
                                onClick={(e) =>
                                  handleChange("built_up_area_unit", item)
                                }
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </TextValidator>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextValidator
                  variant="outlined"
                  className={`${classes.formInputFeild2} dropdown-select ${
                    focusedElement === "superBuiltUpArea" &&
                    classes.focusedInput
                  }`}
                  placeholder="Super builtup area"
                  value={superBuiltUpArea}
                  size="normal"
                  sx={{ width: "230px" }}
                  onChange={(e) => setSuperBuiltUpArea(e.target.value)}
                  onFocus={() => handleFocus("superBuiltUpArea")}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ margin: "0px" }}>
                        <TextValidator
                          variant="outlined"
                          autoComplete="off"
                          select
                          id="emailInput"
                          placeholder="Expected by"
                          className={`inner-select`}
                          name="expected_time_of_possession"
                          value={units.super_built_up_area_unit}
                          size="normal"
                          validators={["required"]} // Add this line
                          errorMessages={[
                            "Expected time of possession is required",
                          ]}
                          style={{ width: "80px" }}
                        >
                          {_areas.map((item, index) => {
                            return (
                              <MenuItem
                                value={item}
                                key={index}
                                onClick={(e) =>
                                  handleChange("super_built_up_area_unit", item)
                                }
                                style={{ textTransform: "capitalize" }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </TextValidator>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            <div
              className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
            >
              {yourPropertyName !== "Plots/lands" &&
                yourPropertyName !== "Agricultural/Farmlands" && (
                  <>
                    <Typography
                      className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                    >
                      Property on Floor
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      className={`${classes.formInputFeild} ${
                        focusedElement === "propertyOnFloor" &&
                        classes.focusedInput
                      }`}
                      placeholder="Enter Property on Floor"
                      value={propertyOnFloor}
                      size="normal"
                      onChange={(e) => setPropertyOnFloor(e.target.value)}
                      onFocus={() => handleFocus("propertyOnFloor")}
                      onBlur={handleBlur}
                      validators={["required"]} // Add this line
                      errorMessages={["Property on Floor is required"]} // Add this line
                    />
                  </>
                )}
              {(yourPropertyName === "Plots/lands" ||
                yourPropertyName === "Agricultural/Farmlands") && (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Number of open sides
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "noOfOpenSides" && classes.focusedInput
                    }`}
                    value={noOfOpenSides}
                    size="normal"
                    onChange={(e) => setNoOfOpenSides(e.target.value)}
                    onFocus={() => handleFocus("noOfOpenSides")}
                    onBlur={handleBlur}
                    select
                    validators={["required"]} // Add this line
                    errorMessages={["This field is required"]} // Add this line
                  >
                    {feildsList?.no_of_open_sides?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.text}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              )}
              {yourPropertyName !== "Plots/lands" &&
                yourPropertyName !== "Agricultural/Farmlands" && (
                  <>
                    <Typography
                      className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                    >
                      Availability
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      className={`${classes.formInputFeild} ${
                        focusedElement === "availability" &&
                        classes.focusedInput
                      }`}
                      value={availability}
                      size="normal"
                      onChange={(e) => setAvailability(e.target.value)}
                      onFocus={() => handleFocus("availability")}
                      onBlur={handleBlur}
                      select
                      validators={["required"]} // Add this line
                      errorMessages={["Availability is required"]} // Add this line
                    >
                      {feildsList?.availability?.map((item) => (
                        <MenuItem value={item?._id} key={item?._id}>
                          {item?.text}
                        </MenuItem>
                      ))}
                    </StyledInput>
                  </>
                )}
              {availabilityName === "Ready to move" ? (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Age of Property
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "ageOfProperty" && classes.focusedInput
                    }`}
                    value={ageOfProperty}
                    size="normal"
                    onChange={(e) => setAgeOfProperty(e.target.value)}
                    onFocus={() => handleFocus("ageOfProperty")}
                    onBlur={handleBlur}
                    select // Add this line
                  >
                    {feildsList?.age_of_property?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.text}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              ) : (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    {yourPropertyName === "Plots/lands" ||
                    yourPropertyName === "Agricultural/Farmlands"
                      ? "Expected time of possession"
                      : "Under Construction"}
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "underConstruction" &&
                      classes.focusedInput
                    }`}
                    value={underConstruction}
                    size="normal"
                    onChange={(e) => setUnderConstruction(e.target.value)}
                    onFocus={() => handleFocus("underConstruction")}
                    onBlur={handleBlur}
                    select // Add this line
                  >
                    {feildsList?.expected_time_of_possession?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.text}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              )}
            </div>
            {yourPropertyName === "Residential" && (
              <div
                className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
              >
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Rooms
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "rooms" && classes.focusedInput
                  }`}
                  value={rooms}
                  size="normal"
                  onChange={(e) => setRooms(e.target.value)}
                  onFocus={() => handleFocus("rooms")}
                  onBlur={handleBlur}
                  select // Add this line
                >
                  {feildsList?.other_rooms?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.text}
                    </MenuItem>
                  ))}
                </StyledInput>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  No.of Bedrooms
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "noOfBedRooms" && classes.focusedInput
                  }`}
                  value={noOfBedRooms}
                  size="normal"
                  onChange={(e) => setNoOfBedRooms(e.target.value)}
                  onFocus={() => handleFocus("noOfBedRooms")}
                  onBlur={handleBlur}
                  select // Add this line
                >
                  {feildsList?.no_of_bedrooms?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.text}
                    </MenuItem>
                  ))}
                </StyledInput>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  No.of Bathrooms
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "noOfBathrooms" && classes.focusedInput
                  }`}
                  value={noOfBathrooms}
                  size="normal"
                  onChange={(e) => setNoOfBathrooms(e.target.value)}
                  onFocus={() => handleFocus("noOfBathrooms")}
                  onBlur={handleBlur}
                  select // Add this line
                >
                  {feildsList?.no_of_bathrooms?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.text}
                    </MenuItem>
                  ))}
                </StyledInput>
              </div>
            )}
            <div
              className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
            >
              {yourPropertyName === "Residential" && (
                <>
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Furnishing Status
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "furnishingStatus" &&
                      classes.focusedInput
                    }`}
                    value={furnishingStatus}
                    size="normal"
                    onChange={(e) => setFurnishingStatus(e.target.value)}
                    onFocus={() => handleFocus("furnishingStatus")}
                    onBlur={handleBlur}
                    select // Add this line
                  >
                    {feildsList?.furnishing_status?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.text}
                      </MenuItem>
                    ))}
                  </StyledInput>
                </>
              )}
              <Typography
                className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
              >
                Ownership
              </Typography>
              <StyledInput
                variant="outlined"
                className={`${classes.formInputFeild} ${
                  focusedElement === "ownership" && classes.focusedInput
                }`}
                value={ownership}
                size="normal"
                onChange={(e) => setOwnership(e.target.value)}
                onFocus={() => handleFocus("ownership")}
                onBlur={handleBlur}
                select // Add this line
              >
                {feildsList?.ownership?.map((item) => (
                  <MenuItem value={item?._id} key={item?._id}>
                    {item?.text}
                  </MenuItem>
                ))}
              </StyledInput>
              {yourPropertyName === "New Projects" && (
                <>
                  {" "}
                  <Typography
                    className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                  >
                    Add Carpet Area
                  </Typography>
                  <StyledInput
                    variant="outlined"
                    className={`${classes.formInputFeild} ${
                      focusedElement === "areaForBhk" && classes.focusedInput
                    }`}
                    placeholder="Add carpet area for Selected BHK "
                    value={areaForBhk}
                    size="normal"
                    onChange={(e) => setAreaForBhk(e.target.value)}
                    onFocus={() => handleFocus("areaForBhk")}
                    onBlur={handleBlur}
                    validators={["required"]} // Add this line
                    errorMessages={["This Feild is required"]} // Add this line
                  />
                </>
              )}
              {yourPropertyName === "Commercial" &&
                propertyTypeName === "Shops/Retails" && (
                  <>
                    <Typography
                      className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                    >
                      No. of Washroom
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      className={`${classes.formInputFeild} ${
                        focusedElement === "washroom" && classes.focusedInput
                      }`}
                      value={noOfWashrooms}
                      size="normal"
                      onChange={(e) => setNoOfWashrooms(e.target.value)}
                      onFocus={() => handleFocus("washroom")}
                      onBlur={handleBlur}
                      select // Add this line
                    >
                      {feildsList?.no_of_washrooms?.map((item) => (
                        <MenuItem value={item?._id} key={item?._id}>
                          {item?.text}
                        </MenuItem>
                      ))}
                    </StyledInput>
                    <Typography
                      className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                    >
                      No. of Balcony
                    </Typography>
                    <StyledInput
                      variant="outlined"
                      className={`${classes.formInputFeild} ${
                        focusedElement === "balcony" && classes.focusedInput
                      }`}
                      value={noOfBalconies}
                      size="normal"
                      onChange={(e) => setNoOfBalconies(e.target.value)}
                      onFocus={() => handleFocus("balcony")}
                      onBlur={handleBlur}
                      select // Add this line
                    >
                      {feildsList?.no_of_washrooms?.map((item) => (
                        <MenuItem value={item?._id} key={item?._id}>
                          {item?.text}
                        </MenuItem>
                      ))}
                    </StyledInput>
                  </>
                )}
            </div>
          </div>
          <Divider className="mt-4 mb-4" />
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Add Pricing
          </Typography>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.overflowClass}`}
          >
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Pricing
            </Typography>
            <StyledInput
              variant="outlined"
              value={price}
              placeholder="Expected Price"
              onChange={async (e) => {
                let _val = e.target.value;
                let replacedVal = _val.replaceAll(",", "");
                let a = await numberWithCommas(replacedVal);
                setPrice(a);
              }}
              className={`${classes.formInputFeild} ${
                focusedElement === "price" && classes.focusedInput
              }`}
              inputProps={{
                style: { textAlign: "right" }, // Right-align the text
              }}
              // startAdornment="₹" // Display ₹ symbol before the input
              // endAdornment={<span>INR</span>} // Optionally, display "INR" after the input
              inputComponent="input"
              onKeyPress={Numeric}
              onFocus={() => handleFocus("price")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Please Select User"]} // Add this line
              renderValue={() => formatToIndianCurrency(price)}
            />
            {/* <StyledInput
              variant="outlined"
              className={`${classes.formInputFeild} ${
                focusedElement === "price" && classes.focusedInput
              }`}
              value={price}
              onKeyPress={Numeric}
              placeholder="Expected Price"
              size="normal"
              renderValue={() => formatToIndianCurrency(price)}
              onChange={(e) => setPrice(e.target.value)}
              onFocus={() => handleFocus("price")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Please Select User"]} // Add this line
            /> */}
            {feildsList?.pricing_details?.map((item) => {
              return (
                <Button
                  key={item._id}
                  className={`${classes.pricingDDetailsBtn} ${
                    pricingDDetails.includes(item._id) &&
                    classes.pricingDDetailsBtnActive
                  }`}
                  variant="outlined"
                  onClick={() => handleSetPricingDetails(item._id)}
                >
                  <span>{item?.text}</span>
                  <Checkbox
                    checked={pricingDDetails.includes(item._id)}
                    sx={{ padding: 0 }}
                    className={`${
                      pricingDDetails.includes(item._id) &&
                      classes.pricingDDetailsBtnActiveIcon
                    }`}
                  />
                </Button>
              );
            })}
          </div>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
          >
            {yourPropertyName === "New Projects" && (
              <>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Approved authority
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "approvedAuthority" &&
                    classes.focusedInput
                  }`}
                  placeholder="Eg: BMRDA,BBMP etc"
                  value={approvedAuthority}
                  size="normal"
                  onChange={(e) => setApprovedAuthority(e.target.value)}
                  onFocus={() => handleFocus("approvedAuthority")}
                  onBlur={handleBlur}
                  validators={["required"]} // Add this line
                  errorMessages={["This Feild is required"]} // Add this line
                />
              </>
            )}
            {(yourPropertyName === "Plots/lands" ||
              yourPropertyName === "Agricultural/Farmlands") && (
              <>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Approved authority
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "approvedAuthority" &&
                    classes.focusedInput
                  }`}
                  placeholder="Eg: BMRDA,BBMP etc"
                  value={approvedAuthority}
                  size="normal"
                  onChange={(e) => setApprovedAuthority(e.target.value)}
                  onFocus={() => handleFocus("approvedAuthority")}
                  onBlur={handleBlur}
                  validators={["required"]} // Add this line
                  errorMessages={["This Feild is required"]} // Add this line
                />
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Any Is it pre-leased/Pre-rented?
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "preleased" && classes.focusedInput
                  }`}
                  value={preLeased}
                  size="normal"
                  onChange={(e) => setPreLeased(e.target.value)}
                  onFocus={() => handleFocus("preleased")}
                  onBlur={handleBlur}
                  select
                  validators={["required"]} // Add this line
                  errorMessages={["This field is required"]} // Add this line
                >
                  {["yes", "no"]?.map((item) => (
                    <MenuItem
                      value={item}
                      key={item}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </StyledInput>
              </>
            )}
          </div>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.formInputContaine1}`}
          >
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Extra Details
            </Typography>
            <StyledInput
              variant="outlined"
              className={`${classes.formInputFeild1} ${
                focusedElement === "pricedetails" && classes.focusedInput
              }`}
              fullWidth
              multiline
              rows={3}
              value={pricedetails}
              size="normal"
              onChange={(e) => setPricedetails(e.target.value)}
              onFocus={() => handleFocus("pricedetails")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Please Select User"]} // Add this line
            />
          </div>
          <Divider className="mt-4 mb-4" />
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Add Photos
          </Typography>
          <Row>
            <Col md={6}>
              <Typography
                className={`mt-4 ${classes.AddPropertyInputLable} ${classes.LocationLable}`}
              >
                Upload Your Photos
              </Typography>
              <div className={`mt-2`}>
                <DragAndDrops
                  onDropFunction={onDropImages}
                  images={files}
                  accept=".png, .jpg, .jpeg,"
                />
                <Typography className={classes.NoteText}>
                  Note: Please add Only Images here
                </Typography>
              </div>
            </Col>
            <Col md={6}>
              <Typography
                className={`mt-4 ${classes.AddPropertyInputLable} ${classes.LocationLable}`}
              >
                Upload Your Videos
              </Typography>
              <div className={`mt-2`}>
                <DragAndDrops
                  onDropFunction={onDropVideos}
                  images={files}
                  accept=".mp4, .avi, .mov"
                />
                <Typography className={classes.NoteText}>
                  Note: Please add Only Videos here
                </Typography>
              </div>
            </Col>
          </Row>
          {files && files.length > 0 && (
            <div
              className={`mt-4 ${classes.formInputContainer} ${classes.imagesContainer}`}
            >
              {files.map((item, index) => {
                const url = item.mime_type
                  ? `${process.env.REACT_APP_API_URL}/${
                      item.video_name || item.image
                    }`
                  : item.type
                  ? URL.createObjectURL(item)
                  : item.url;
                const isVideo = item.mime_type
                  ? item.mime_type.includes("video")
                  : !item.mime_type && !item.type;

                return (
                  <div key={index}>
                    {isVideo ? (
                      <VideoItem
                        src={url}
                        alt={item.name}
                        className={classes.AddPropertyImages}
                        callback={() => loadVideo(url)}
                      />
                    ) : (
                      <img
                        src={url}
                        alt={item.name}
                        className={classes.AddPropertyImages}
                      />
                    )}
                    <div className={classes.ImageNameContainer}>
                      <Typography className={classes.ImageNameTag}>
                        {item.original_name || item.name}
                      </Typography>
                      <span
                        className={classes.deleteIcon}
                        onClick={() => removeFile(index)}
                      >
                        <MdDelete />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Divider className="mt-4 mb-4" />
          <Typography className={`mt-4 ${classes.AddPropertySubHeader}`}>
            Add Amenities
          </Typography>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
          >
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Amenities
            </Typography>
            <Select
              variant="outlined"
              className={`${classes.selectInput} ${
                focusedElement === "aminities" && classes.focusedInput
              }`}
              placeholder="Enter Area"
              select
              value={aminities}
              multiple
              size="normal"
              renderValue={(selected) => {
                return (
                  <em style={{ textTransform: "capitalize" }}>
                    {selected
                      ?.map((value) => {
                        const item = feildsList?.amenities.find(
                          (item) => item?._id === value
                        );
                        return item?.text;
                      })
                      .join(", ")}
                  </em>
                );
              }}
              onChange={(e) => setAminities(e.target.value)}
              onFocus={() => handleFocus("aminities")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Amenities is required"]} // Add this line
            >
              {feildsList?.amenities?.map((item) => (
                <MenuItem
                  value={item?._id}
                  key={item?._id}
                  className={classes.CheckBoxContainer}
                >
                  <Checkbox
                    sx={{ padding: 0 }}
                    color="primary"
                    checked={aminities.includes(item?._id)}
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                  <span
                    className={`${
                      aminities.includes(item?._id) && classes.checkboxText
                    }`}
                  >
                    {item?.text}
                  </span>
                </MenuItem>
              ))}
            </Select>
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Other Features
            </Typography>
            <Select
              variant="outlined"
              className={`${classes.selectInput} ${
                focusedElement === "otherFeatures" && classes.focusedInput
              }`}
              value={otherFeatures}
              size="normal"
              multiple
              onChange={(e) => setOtherFeatures(e.target.value)}
              onFocus={() => handleFocus("otherFeatures")}
              onBlur={handleBlur}
              select
              renderValue={(selected) => {
                return (
                  <em style={{ textTransform: "capitalize" }}>
                    {selected
                      ?.map((value) => {
                        const item = feildsList?.other_features.find(
                          (item) => item?._id === value
                        );
                        return item.text;
                      })
                      .join(", ")}
                  </em>
                );
              }}
              validators={["required"]} // Add this line
              errorMessages={["Other Features is required"]} // Add this line
            >
              {feildsList?.other_features?.map((item) => (
                <MenuItem
                  value={item?._id}
                  key={item?._id}
                  className={classes.CheckBoxContainer}
                >
                  <Checkbox
                    sx={{ padding: 0 }}
                    color="primary"
                    checked={otherFeatures.includes(item?._id)}
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                  <span
                    className={`${
                      otherFeatures.includes(item?._id) && classes.checkboxText
                    }`}
                  >
                    {item?.text}
                  </span>
                </MenuItem>
              ))}
            </Select>
            {yourPropertyName === "Residential" && (
              <>
                {" "}
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Power Backup
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "powerBackup" && classes.focusedInput
                  }`}
                  value={powerBackup}
                  size="normal"
                  onChange={(e) => setPowerBackup(e.target.value)}
                  onFocus={() => handleFocus("powerBackup")}
                  onBlur={handleBlur}
                  select
                  validators={["required"]} // Add this line
                  errorMessages={["Power Backup is required"]} // Add this line
                >
                  {feildsList?.power_backup?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.text}
                    </MenuItem>
                  ))}
                </StyledInput>
              </>
            )}
          </div>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.LocationContainer}`}
          >
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Property Facing
            </Typography>
            <StyledInput
              variant="outlined"
              className={`${classes.formInputFeild} ${
                focusedElement === "propertyFacing" && classes.focusedInput
              }`}
              placeholder=""
              value={propertyFacing}
              select
              size="normal"
              onChange={(e) => setPropertyFacing(e.target.value)}
              onFocus={() => handleFocus("propertyFacing")}
              onBlur={handleBlur}
              validators={["required"]} // Add this line
              errorMessages={["Property Facing is required"]} // Add this line
            >
              {feildsList?.property_facing?.map((item) => (
                <MenuItem value={item?._id} key={item?._id}>
                  {item?.text}
                </MenuItem>
              ))}
            </StyledInput>
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Location advantages
            </Typography>
            <Select
              variant="outlined"
              className={`${classes.selectInput} ${
                focusedElement === "locationAdvantages" && classes.focusedInput
              }`}
              value={locationAdvantages}
              size="normal"
              onChange={(e) => setLocationAdvantages(e.target.value)}
              onFocus={() => handleFocus("locationAdvantages")}
              onBlur={handleBlur}
              select
              renderValue={(selected) => {
                return (
                  <em style={{ textTransform: "capitalize" }}>
                    {selected
                      ?.map((value) => {
                        const item = feildsList?.location_advantages.find(
                          (item) => item?._id === value
                        );
                        return item.text;
                      })
                      .join(", ")}
                  </em>
                );
              }}
              multiple
              validators={["required"]} // Add this line
              errorMessages={["Location advantages is required"]} // Add this line
            >
              {feildsList?.location_advantages?.map((item) => (
                <MenuItem
                  value={item?._id}
                  key={item?._id}
                  className={classes.CheckBoxContainer}
                >
                  <Checkbox
                    sx={{ padding: 0 }}
                    color="primary"
                    checked={locationAdvantages.includes(item?._id)}
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                  <span
                    className={`${
                      locationAdvantages.includes(item?._id) &&
                      classes.checkboxText
                    }`}
                  >
                    {item?.text}
                  </span>
                </MenuItem>
              ))}
            </Select>
            {yourPropertyName === "Residential" && (
              <>
                <Typography
                  className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
                >
                  Water Resouces
                </Typography>
                <StyledInput
                  variant="outlined"
                  className={`${classes.formInputFeild} ${
                    focusedElement === "otherAminities" && classes.focusedInput
                  }`}
                  value={otherAminities}
                  size="normal"
                  onChange={(e) => setOtherAminities(e.target.value)}
                  onFocus={() => handleFocus("otherAminities")}
                  onBlur={handleBlur}
                  select
                >
                  {feildsList?.water_source?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.text}
                    </MenuItem>
                  ))}
                </StyledInput>
              </>
            )}
          </div>
          <div
            className={`mt-4 ${classes.formInputContainer} ${classes.formInputContaine1}`}
          >
            <Typography
              className={`${classes.AddPropertyInputLable} ${classes.LocationLable}`}
            >
              Extra Details
            </Typography>
            <StyledInput
              variant="outlined"
              className={`${classes.formInputFeild1} ${
                focusedElement === "OtherAminitiesDetails" &&
                classes.focusedInput
              }`}
              fullWidth
              multiline
              rows={3}
              value={OtherAminitiesDetails}
              size="normal"
              onChange={(e) => setOtherAminitiesDetails(e.target.value)}
              onFocus={() => handleFocus("OtherAminitiesDetails")}
              onBlur={handleBlur}
            />
          </div>
          <div className={`mt-4 ${classes.formBtnContainer}`}>
            <Button
              variant="contained"
              className={classes.formSubmitBtn}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              className={`${classes.formSubmitBtn} ${classes.formCancelBtn}`}
              onClick={() => navigate("/property")}
            >
              Cancel
            </Button>
          </div>
        </ValidatorForm>
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && (
        <SuccessModal
          modalClose={CloseAlert}
          heading={
            window.location.pathname.includes("edit")
              ? "Property Updated SuccessFully"
              : "Property Added SuccessFully"
          }
          subHeading={
            window.location.pathname.includes("edit")
              ? "Redirecting to Property List page..."
              : "Redirecting to Property List page..."
          }
          loader={false}
        />
      )}
      {deleteConfirmation && (
        <ConfirmModal
          modalClose={CloseAlert}
          heading={"Delete Confirmation"}
          subHeading={`Are you sure want to delete this property?`}
          callback={handleClickDelete}
        />
      )}
      {videoModal && <VideoModal modalClose={CloseAlert} video={playVideo} />}
      <GoogleMapPop
        open={mapPopup}
        handleClose={handleClose}
        isLoaded={isLoaded}
        setMap={setMap}
        marker={marker}
        centerPoint={centerPoint}
        setCenterPoint={setCenterPoint}
        handleClick={handleClick}
        handleAddMaps={handleAddMaps}
      />
    </div>
  );
};

export default AddProperty;
